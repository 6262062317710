import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from 'components/layout/layoutMain';
import LayoutPanel from 'components/layout/layoutPanel';

import Faq from 'components/common/Faq';
import faqData from 'constants/components/accordion-list/docusign-support-faq';

import picture1 from 'images/docusign-support/Picture1.png';
import picture2 from 'images/docusign-support/Picture2.png';

function DocuSignSupport() {

    let modifiedAccordionList = faqData;

    let accordionItem1 = {
        "title": `I've received a document to sign, but I'm the wrong person to sign this.`,
        "content": `<p>If you cannot sign off a DocuSign document there are two options;</p><ol><li>You can request that the Sender re-assign the DocuSign review to a different person and different email address.</li><li>You can open the Envelope by clicking the yellow ‘Review Documents’ button, then, under ‘Other Actions’ you can re-assign to the appropriate person.</li></ol><p class="text-center"><img src=${picture1} width="275" alt="Sample picture" /></p>`
        
    };

    let accordionItem2 = {
        "title": `Can I save my progress and finish later?`,
        "content": `<p>Yes, you can save and complete a review as you go. Find the tab titled ‘other actions’ and select ‘finish later’.</p><p class="text-center"><img src=${picture2} width="275" alt="Sample picture" /></p>`
    }

    modifiedAccordionList.splice(3, 0, accordionItem1);
    modifiedAccordionList.splice(4, 0, accordionItem2);

	return (
		<>
            <SEO title="DocuSign frequently asked questions | Kleenheat for Business"
                 titleBespoke="true"
            	 description="DocuSign is a secure online platform allowing customers to sign contracts electronically."
        	/>

            <Header />
            <LayoutMain>

            	<LayoutPanel background="mer-bg--primary-cyan"                 
			                 padding="mer-py-lg"
			                 theme="mer-theme--cyan">
			        <div className="container">
                        <div className="row mer-panel justify-content-around align-items-middle">
                            <div className="col-12 mer-text--align-center mer-text--size-lg">
                                <h1 className="my-0 mer-typography--headline2">DocuSign frequently asked questions</h1>
                            </div>
                        </div>
                    </div>
			    </LayoutPanel>

                <LayoutPanel background="mer-bg--gs-grey-light"      
                             border="mer-border--bottom"           
			                 padding="mer-py-de">
                    <div className="container">
                        <div className="row mer-panel">
                            <div className="col-12 col-sm-10 col-lg-8 col-xl-7 mer-text--align-center">
                                <div className="content">
                                    <p>DocuSign is a secure online platform allowing customers to sign contracts electronically. In this instance, Kleenheat signs contracts with our business customers via DocuSign as part of our contract signing process.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>

                <Faq panelBackground="mer-bg--gs-grey-lighter"
		  			 panelPadding="mer-pt-de mer-pb-lg"
		  			 id="faq"
                     hideHeading="true"
		  			 data={modifiedAccordionList} />

		    </LayoutMain>
            <Footer />
		</>
	)
}

export default DocuSignSupport;